<template>
  <div class="dashboard__container" v-if="user">
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col">
        <img class="galleryImage" :src="user.id_card_path" />
      </div>
      <div class="dashboard__container--body--col">
        <div class="whiteBack mb-3">
          <input type="file" ref="file" id="imageUpload" accept="image/*" @change="previewImage" hidden>
          <button @click="chooseFile()" class="btn btn__primary mb-2" v-if="!userProfile.id_card_path">Upload Image</button>
          <button @click="chooseFile()" class="btn btn__primary mb-2" v-if="userProfile.id_card_path">Upload New Image</button>
        </div>
        <progress :value="uploadValue" max="100" v-if="showBar"></progress>
      </div>
      
    </div>
  </div>
</template>

<style scoped>
  .galleryImage {
    width: 100%;
    height: auto;
  }
</style>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['user'],
  name: 'userIdUpload',
  data: () => ({ 
    imageData: null,
    uploadValue: 0,
    showBar:false,
    picture: null,
    performingRequest: false,
  }),
  methods: {
    chooseFile() {
        document.getElementById("imageUpload").click()
    },
    previewImage(event) {
      this.uploadValue=0;
      this.picture=null
      this.imageData=event.target.files[0]
      this.onUpload()
    },
    onUpload() {
      this.showBar = true
      let user = this.user
      user.id_i9_verification =null
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url) => {
          user.id_i9_verification=url
          this.$store.dispatch('updateUser', user)
        })
        this.showBar = false
      })
      this.imageData = null
      this.$refs.file.value=null
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
  },
  components: {
    Loader,
  },
}

</script>